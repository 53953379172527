import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  Login,
  OAuth2RedirectHandler,
  Register,
  ForgotPassword,
  ResetPassword,
  VerifyAccount,
} from "../authentication";
//import OAuth2RedirectHandler from "../authentication/oauth2/OAuth2RedirectHandler";
import HomeContainer from "./../pages/Homepage";
import HostelListPage from "./../pages/HostelListPage";
import HostelDetailPage from "./../pages/HostelDetailPage";
import { NotFound } from "./404";
import { BookingDetail } from "../pages/BookingDetail/BookingDetail";
import { AboutUs } from "../pages/AboutUs/AboutUs";
import { ContactUs } from "../pages/ContactUs/ContactUs";
import { TermsOfUse } from "../pages/TermsOfUse/TermsOfUse";
import { Logout } from "../pages/Logout/Logout";
import { BookingSuccess } from "../pages/BookingSuccess/BookingSuccess";
import { BookingFailed } from "../pages/BookingFailed/BookingFailed";
import { ManageBookings } from "../pages/ManageBookings/ManageBookings";
import { UpdateProfile } from "../pages/UpdateProfile/UpdateProfile";
import { PaymentMethods } from "../pages/PaymentMethods/PaymentMethods";
import { NotificationSettings } from "../pages/NotificationSettings/NotificationSettings";
import { SecuritySettings } from "../pages/SecuritySettings/SecuritySettings";
import { HelpSupport } from "../pages/HelpSupport/HelpSupport";
import { PrivacyPolicy } from "../pages/PrivacyPolicy/PrivacyPolicy";
import { FAQs } from "../pages/FAQs/FAQs";
import { MakeEnquiry } from "../pages/MakeEnquiry/MakeEnquiry";
import { BookingExist } from "../pages/BookingExist/BookingExist";
import { ComplaintsAndFeedback } from "../pages/ComplaintsAndFeedback/ComplaintsAndFeedback";
import BlogList from "../components/blogs/BlogList";

export const RouterConfig = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<HomeContainer />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route exact path="/verify-account" element={<VerifyAccount />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/terms-of-use" element={<TermsOfUse />} />
        <Route exact path="/hostel-list/:place" element={<HostelListPage />} />
        <Route exact path="/make-enquiry" element={<MakeEnquiry />} />
        <Route exact path="/blog" element={<BlogList />} />
        <Route
          exact
          path="/hostel-details/:name/:id"
          element={<HostelDetailPage />}
        />
        <Route exact path="/booking-detail" element={<BookingDetail />} />
        <Route exact path="/booking-success" element={<BookingSuccess />} />
        <Route exact path="/booking-failed" element={<BookingFailed />} />
        <Route exact path="/manage-bookings" element={<ManageBookings />} />
        <Route exact path="/update-profile" element={<UpdateProfile />} />
        <Route
          exact
          path="/complaints-and-feedback"
          element={<ComplaintsAndFeedback />}
        />
        <Route exact path="/payment-methods" element={<PaymentMethods />} />
        <Route exact path="/booking-exist" element={<BookingExist />} />
        <Route
          exact
          path="/notification-settings"
          element={<NotificationSettings />}
        />
        <Route exact path="/security-settings" element={<SecuritySettings />} />
        <Route exact path="/support" element={<HelpSupport />} />
        <Route path="*" element={<NotFound />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/faqs" element={<FAQs />} />
        <Route
          exact
          path="/oauth2/redirect"
          element={<OAuth2RedirectHandler />}
        />
      </Routes>
    </div>
  );
};
