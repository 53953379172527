import { Card, Col, Layout, Row, Space } from 'antd';
import React from 'react';
import Header from '../header/Header';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { Container } from '@mui/material';
import "../../pages/TermsOfUse/TermsOfUse.scss";

const BlogList = (props) => {
    return (
        <div className="TermsOfUse">
            <Header />
            <Navbar />
            <Container>
                <main className="tou__main-container">
                    <section className="tou__left">
                        <h2 style={{ color: "#ff6600" }}>Blog</h2>
                        <h2>Pune: The Oxford of the East and a Thriving Education Hub</h2>
                        <div>
                            <p>
                                Pune, often referred to as the &quot;Oxford of the East,&quot; is a city synonymous with education, culture, and
                                vibrant student life. Every year, thousands of students from across India and abroad flock to Pune to
                                pursue higher education in its prestigious institutions. From engineering and medical colleges to
                                business schools and vocational institutes, Pune caters to diverse academic interests, making it one
                                of the most sought-after education hubs in the country.
                            </p>
                            <h3>The Growing Demand for Student Accommodation</h3>
                            <p>
                                As Pune&#39;s reputation as an educational destination continues to soar, so does the demand for quality
                                student accommodation. According to recent statistics, Pune hosts over 200,000 students annually,
                                with many coming from different states and countries. While the city offers numerous colleges and
                                universities, the availability of reliable and comfortable student housing has not kept pace with this
                                influx.
                            </p>
                            <p>
                                Students often struggle to find accommodation that is both affordable and conducive to their
                                academic pursuits. Common issues include:
                            </p>
                            <ul>
                                <li>Overcrowded and poorly maintained PGs.</li>
                                <li>High rents in areas close to educational institutions.</li>
                                <li>Lack of safety and security.</li>
                                <li>Minimal amenities and a lack of a homely environment.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>MyHostelRoom: Redefining Student Accommodation in Pune</h3>
                            <p>
                                This is where <b>MyHostelRoom</b> steps in to bridge the gap and transform the student living experience.
                                Launched in <b>June 2023</b>, MyHostelRoom has quickly become Pune&#39;s go-to solution for organized,
                                comfortable, and secure student accommodations. With <b>15 hostels strategically located </b>across
                                Pune—including key areas like Wakad, Vimannagar, BMCC Road, Senapati Bapat Road, and Shaniwar
                                Peth—MyHostelRoom caters to students&#39; diverse needs.
                            </p>
                        </div>

                        <div>
                            <h3>Why Choose MyHostelRoom?</h3>
                            <ol>
                                <li>Prime Locations: Our hostels are located near major educational institutions and coaching
                                    centers, ensuring students save time and energy on commuting.</li>

                                <li>Comfortable Living: From spacious rooms and cozy lounges to high-tech amenities like
                                    biometric access and automated rent collection, we provide a hassle-free living experience.</li>

                                <li>Safety and Security: With round-the-clock security, high-tech surveillance, and caring
                                    rectors, students and their families can rest assured about their well-being.</li>

                                <li>Amenities Galore: We offer services that go beyond accommodation—doctor-on-call,
                                    psychologist-on-call, beautician visits, and monthly events that focus on personality
                                    development and self-defense.</li>

                                <li>Homely Atmosphere: Our hostels celebrate Marathi traditions and festivals like
                                    Nagpanchami, Mangalagauri, and Krishna Janmashtami, helping students feel at home while
                                    building a sense of community.</li>
                            </ol>
                        </div>

                        <div>

                            <h3>Making Life Easier for Students</h3>
                            <p>
                                At MyHostelRoom, we believe that students should focus on what matters most: their education and
                                career goals. By providing a supportive environment with all the comforts of home, we eliminate the
                                stress and distractions often associated with finding and living in hostels or PGs. Our properties
                                accommodate students pursuing a variety of goals, including college degrees, competitive exams,
                                internships, and vocational training programs.
                            </p>
                            <p>
                                Whether you&#39;re looking for a shared or private room, a vibrant community, or a quiet space to study,
                                MyHostelRoom ensures every student’s needs are met.
                            </p>
                        </div>

                        <div>
                            <h3>Conclusion</h3>
                            <p>
                                In a city as dynamic as Pune, finding the right student accommodation shouldn’t be a challenge.
                                MyHostelRoom is proud to be a trusted partner for students navigating this exciting phase of their
                                lives. With our commitment to quality, comfort, and community, we’re here to redefine student
                                living in Pune.
                            </p>
                            <p>
                                Looking for a hostel in Pune? Explore MyHostelRoom today and discover the perfect place to call
                                home during your academic journey.
                            </p>
                        </div>
                    </section>

                    <aside className="tou__right"></aside>
                </main>
            </Container>
            <Row>
                <Col span={24}>
                    <Footer />
                </Col>
            </Row>
        </div>
    );
}

export default BlogList;