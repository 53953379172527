import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import "./Header.scss";
import UserNavigation from "../user-navigation/UserNavigation";
import useToken from "../../services/useToken";
import Drawer from "@mui/material/Drawer";
import { utility } from "../../utils/utility";
import { getUserDetail } from "../../services/httpService";
import { Button } from "antd";

export default function Header(props) {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { token, userId } = useToken();
  const [loginText, setLoginText] = useState("");
  const [userData, setUserData] = useState();
  const [profilePicUrl, setprofilePicUrl] = useState();
  const navigate = useNavigate();

  const handleShowNav = () => {
    token ? setDrawerOpen(true) : redirectToLogin();
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  const handleGetUserDetail = async () => {
    //console.log('userId',userId);
    const user = await getUserDetail({
      userId,
      token,
    });
    //console.log(user?.response.fullName);
    if (user) {
      console.log(
        ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> ",
        user?.response?.bookingStatus[0]
      );
      setUserData(user?.response);
      setLoginText(user?.response.fullName);
      setprofilePicUrl(user?.response?.imageAttachment?.imagePath);
      localStorage.setItem("bookingStatus", user?.response?.bookingStatus[0]);
    }
  };

  const handleTokenAuthentication = (token) => {
    const t = token?.replace(/"/g, "");
    const m = setInterval(() => {
      const isTokenValid = utility.validateJwtExpiry(t);
      if (!isTokenValid) {
        console.log(isTokenValid);
        navigate("/logout");
        clearInterval(m);
      }
    }, 10000);
  };

  useEffect(() => {
    if (token) {
      handleGetUserDetail();
      handleTokenAuthentication(token);
    } else {
      setLoginText("Login/ Signup");
    }
  }, [token]);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <header className="Header">
      <Container>
        <div className="header__container">
          <Link to="/">
            <img alt="Hostel Logo" src="/logo.png" />
          </Link>
          <div style={{ display: "flex" }}>
            <div className="header__user-container" onClick={handleShowNav}>
              <span>
                {
                  profilePicUrl ? <img src={profilePicUrl} alt="Avatar"
                    style={{ borderRadius: "20px" }}
                  /> :
                    <span className="material-symbols-outlined">person</span>
                }
              </span>
              <span>
                <span>{token ? "Welcome" : ""}</span>
                <span>{loginText}</span>
              </span>
            </div>
            {/* <Button className="header-button" onClick={() => navigate("/contact-us")}>Make an enquiry</Button> */}
          </div>

          <React.Fragment key="left">
            <Drawer anchor="left" open={isDrawerOpen} onClose={closeDrawer}>
              <UserNavigation
                data={{
                  fullName: userData?.fullName,
                  email: userData?.username,
                  profileUrl: profilePicUrl,
                }}
              />
            </Drawer>
          </React.Fragment>
        </div>
      </Container>
    </header>
  );
}
