// material-ui
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

// styles
const LoaderOverlay = styled("div")({
  position: "fixed", // Ensures it covers the whole screen without affecting layout
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimmed background
  zIndex: 9999, // Ensure it's above all other elements
});

const Message = styled("div")({
  marginTop: "16px",
  fontSize: "18px",
  fontWeight: "500",
  color: "#fff",
  textAlign: "center",
});

// ==============================|| LOADER ||============================== //
const Loading = () => (
  <LoaderOverlay>
    <CircularProgress color="primary" />
    <Message>Please wait, we are redirecting you to the payment page...</Message>
  </LoaderOverlay>
);

export default Loading;
