import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Button from "@mui/material/Button";
import "./Homepage.scss";
import { Card, Col, Input, Layout, Row, Space } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import LimitlessSectionImages from "./LimitlessSection/LimitlessSectionImages";
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";
import MetaTag from "../../components/meta-tag/metatag";
import Testimonials from "../Testimonials/Testimonials";
import Events from "../Events/Events";
import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";

export function Homepage() {
  const [errorText, setErrorText] = useState("");
  const [currentLat, setCurrentLat] = useState(18.5424);
  const [currentLng, setCurrentLng] = useState(73.8567);
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("Pune, Maharashtra, India");
  const navigate = useNavigate();
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
  const contentStyle = {
    margin: 0,
    height: '475px',
    color: '#ff6600',
  };

  useEffect(() => {
    if (navigator.userAgent !== "ReactSnap") {
      const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

      if (googleMapsApiKey) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        script.onload = () => {
          console.log("Google Maps API loaded successfully!");
          setGoogleMapsLoaded(true); // Mark as loaded
        };

        script.onerror = () => {
          console.error("Failed to load Google Maps API.");
        };
      } else {
        console.error("Google Maps API key is missing.");
      }
    }
  }, []);

  useEffect(() => {
    if (googleMapsLoaded) {
      let autocomplete;
      let searchInputBox;
      const initAutocomplete = async () => {
        const { Autocomplete } = await window.google?.maps?.importLibrary(
          "places"
        );
        searchInputBox = document.querySelector("#searchTextBox");
        autocomplete = new Autocomplete(searchInputBox, {
          componentRestrictions: { country: ["in"] },
          fields: ["address_components", "geometry"],
          location: { lat: 18.5424, lng: 73.8567 },
          radius: 1000,
          language: "en",
          offset: 3,
        });
        autocomplete.addListener("place_changed", fillInAddress);
      };

      function fillInAddress() {
        const place = autocomplete.getPlace();
        const location = place.geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        setCurrentLat(lat);
        setCurrentLng(lng);
      }
      initAutocomplete();
    }
  }, [googleMapsLoaded]);

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      let searchValue = document.querySelector("#searchTextBox").value;
      let place;
      place = searchValue.replace(/\s/g, "_");
      navigate(`/hostel-list/hostel_in_${place}`, {
        state: { lat: currentLat, lng: currentLng },
      });
    }
  };

  const handleClickSearch = () => {
    let searchValue = document.querySelector("#searchTextBox").value;
    let place;
    if (searchValue === "" || searchValue === undefined) {
      setErrorText("Please enter a seach criteria.");
    } else {
      place = searchValue.replace(/\s/g, "_");
      navigate(`/hostel-list/hostel_in_${place}`, {
        state: { lat: currentLat, lng: currentLng },
      });
    }
  };

  const handleCloseLocation = () => {
    setOpenLocationDialog(false);
  };

  const handleOpenLocation = () => {
    setOpenLocationDialog(true);
  };

  return (
    <>
      <div className="Homepage">
        <MetaTag hostelName={"Default"} />
        <Header />
        {/* <LoggedInHeader /> */}
        <Navbar />
        <Layout
          style={{ padding: 25, backgroundColor: "white" }}>

          {/* banner section start */}
          <Row gutter={8}>
            <Col lg={17} md={17} sm={24} xs={24}>
              <img alt="example" src={require('../../assets/images/homepage/banner.JPG')} style={{ width: "100%", height: 530, borderRadius: 12, opacity: 0.5 }} />
              <div class="banner-centered-text">
                <Space direction="vertical" size={8} style={{ width: "100%" }}>
                  <Row style={{ color: "#000", textAlign: "center" }}>
                    <span className="banner-centered-text-fontsize">Adulting is hard, finding a good Hostel shouldn't be.</span>
                  </Row>
                  <Row>
                    <Card style={{ width: "100%", top: 50 }} bodyStyle={{ padding: 15 }}>
                      <Row gutter={16}>
                        <Col lg={10} md={10} sm={24} xs={24} className="banner-card-text-fontsize" style={{ fontWeight: 600, color: "#000" }}>Spot your <span style={{ color: "#ff6600" }}>hotspot</span>
                        </Col>
                        <Col lg={14} md={14} sm={24} xs={24}>
                          <FormControl
                            className="component__textbox-big"
                            variant="outlined"
                          >
                            <InputLabel htmlFor="searchTextBox">
                              Search your hostel
                            </InputLabel>
                            <OutlinedInput
                              placeholder="Enter city, locality or hostel name"
                              defaultValue={"Pune, Maharashtra, India"}
                              onKeyDown={onEnterPress}
                              id="searchTextBox"
                              type="text"
                              label="Search your hostel"
                              endAdornment={
                                <InputAdornment position="end">
                                  <Button
                                    onClick={handleClickSearch}
                                    variant="contained"
                                    color="primary"
                                  >
                                    Search
                                  </Button>
                                  {/*<IconButton onClick={handleClickSearch} edge="end">
                           <SearchOutlinedIcon /> 
                        </IconButton>*/}
                                </InputAdornment>
                              }
                            />
                            <FormHelperText style={{ color: "red" }}>
                              {errorText}
                            </FormHelperText>
                          </FormControl>
                        </Col>
                      </Row>
                    </Card>
                  </Row>
                </Space>
              </div>
            </Col>
            <Col lg={7} md={7} sm={24} xs={24}>
              <Row className="container">
                <img alt="example" src={require('../../assets/images/homepage/left01.jpg')} style={{ width: "100%", height: 170, borderRadius: 12, marginBottom: 10 }} />
                <div class="limitless-text-block" style={{ fontSize: 15, color: "#000", textAlign: "center", paddingBottom: 16, paddingTop: 8 }}>
                  Are you a dude?<br />We’ve got your hangout spot!</div>
              </Row>
              <Row className="container">
                <img alt="example" src={require('../../assets/images/homepage/left02.JPG')} style={{ width: "100%", height: 170, borderRadius: 12, marginBottom: 10 }} />
                <div class="limitless-text-block" style={{ fontSize: 15, color: "#000", textAlign: "center", paddingBottom: 16, paddingTop: 8 }}>
                  Need a girls' haven?<br />  Your comfy space is set!</div>
              </Row>
              <Row className="container">
                <img alt="example" src={require('../../assets/images/homepage/left03.jpg')} style={{ width: "100%", height: 170, borderRadius: 12, marginBottom: 10 }} />
                <div class="limitless-text-block" style={{ fontSize: 15, color: "#000", textAlign: "center", paddingBottom: 16, paddingTop: 8 }}>
                  Are you working 9 to 5?<br />We’ve got space for you too!</div>
              </Row>
            </Col>
          </Row>
          {/* banner section end */}

          {/* info section start */}
          <Row style={{ marginBottom: 80, marginTop: 80 }}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center" }}>
              <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#ff6600" }}>Your Second Home! <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#000" }}>More Than Just a Place to Live.</span></span>
              <br />
              <span style={{ color: "slategray", fontSize: 22, fontWeight: "bold", textAlign: "center" }}>Find Your Space, Find Your People: The Perfect Balance for Your Accommodation.
              </span>
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginBottom: 80 }}>
            <Col lg={15} md={15} sm={24} xs={24}>
              <Row gutter={16}>
                <Col span={12}>
                  <img alt="example" src={require('../../assets/images/homepage/wall_proof01.jpg')} style={{ width: "100%", height: "85%", borderRadius: 12 }} />
                </Col>
                <Col span={12}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Row>
                      <img alt="example" src={require('../../assets/images/homepage/wall_proof02.jpg')} style={{ width: "75%", height: "50%", borderRadius: 12 }} />
                    </Row>
                    <Row>
                      <img alt="example" src={require('../../assets/images/homepage/wall_proof03.jpg')} style={{ width: "75%", height: "50%", borderRadius: 12 }} />
                    </Row>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col lg={9} md={9} sm={24} xs={24} style={{ textAlign: "left" }}>
              <span style={{ fontSize: 36, fontWeight: "bold", color: "#000" }}>Start living your best life <br />from <span style={{ fontSize: 36, fontWeight: "bold", color: "#ff6600" }}>day one</span></span>
              <Row>
                <span style={{ fontSize: 18 }}>Experience the perfect blend of comfort and convenience at our <b>modern hostel</b>. We provide beautifully <b>furnished rooms</b> that are equipped with everything you need for a comfortable stay. We've thought of everything from <b>comfy beds</b> and <b>plenty of storage to high-speed internet</b>. Our ideal location allows you to easily access everything the city has to offer, while our friendly staff makes you feel right at home.
                </span>
              </Row>
            </Col>
          </Row>
          {/* info section end */}

          {/* limitless living section start */}
          <Row style={{ textAlign: "center" }}>
            <Col span={24}>
              <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#000" }}>Living Life <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#ff6600" }}>healthier, happier, and more accomplished.</span></span>
              <br />
              <span style={{ color: "#000", fontSize: 22, textAlign: "center" }}>You live your life, we'll make sure the rest is effortless!
              </span>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: 20, textAlign: "center" }}>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless01.jpg")} overlayText="High-Speed Wi-Fi, Laundry, & More" />
            </Col>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless02.jpg")} overlayText="Stylish Rooms, Classic Furniture" />
            </Col>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless03.jpg")} overlayText="Your Safety, Our Priority" />
            </Col>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless04.jpg")} overlayText="Live Your Way, We Understand Your Freedom!" />
            </Col>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless05.jpg")} overlayText="Your Kitchen, Your Rules" />
            </Col>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless06.JPG")} overlayText="Strangers to Best Friends, Make Memories" />
            </Col>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless07.JPG")} overlayText="Relax, Recharge, Celebrate" />
            </Col>
            <Col lg={6} sm={12} style={{ paddingLeft: 35, paddingRight: 35 }}>
              <LimitlessSectionImages src={require("../../assets/images/homepage/limitless08.jpg")} overlayText="Daily cleaning and tidying!" />
            </Col>
          </Row>
          {/* limitless living section end */}

          {/* testimonial section start */}
          <Testimonials />
          {/* testimonial section end */}

          <Row style={{ marginBottom: 15, marginTop: 80 }}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center", marginBottom: 25 }}>
              <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#000" }}><span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#ff6600" }}>Our hostels</span> across the Pune</span>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 30 }} justify="space-around" >
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center" }}>
              <img width="100%" alt="example" src={require('../../assets/images/homepage/our-locations.png')} />
            </Col>
          </Row>

          {/* event section start */}
          <Events />
          {/* event section end */}

          <Row style={{ marginTop: 80 }}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center" }}>
              <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#000" }}>We accommodate <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#ff6600" }}> Students</span> from</span>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <img alt="example" src={require('../../assets/images/homepage/our-partner-colleges.png')} />
            </Col>
          </Row>

          <Row style={{ marginBottom: 60, marginTop: 60 }}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center", marginBottom: 15 }}>
              <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#000" }}>Our<span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#ff6600" }}> Partners</span></span>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24}>
              <img alt="example" src={require('../../assets/images/homepage/clients.png')} />
            </Col>
          </Row>

        </Layout>
        <Row>
          <Col span={24}>
            <Footer />
          </Col>
        </Row>
      </div >
    </>
  );
}
