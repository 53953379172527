import { Carousel, Col, Row } from 'antd';
import React from 'react';

const Events = () => {
    return (
        <Row style={{ textAlign: "center", marginTop: 60, paddingBottom: 0 }}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center", marginBottom: 25 }}>
            </Col>
            <Col lg={24} md={0} sm={0} xs={0}>
                <Carousel arrows autoplay={true}>
                    <div>
                        <Row gutter={8}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/1.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/2.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/3.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/4.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/5.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/6.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/7.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/8.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/9.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/10.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/11.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/12.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/13.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/events/9.png')} />
                            </Col>
                        </Row>
                    </div>
                </Carousel>
            </Col>
            <Col lg={0} md={24} sm={24} xs={24}>
                <Carousel arrows autoplay={true}>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/1.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/2.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/3.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/4.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/5.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/6.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/7.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/8.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/9.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/10.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/11.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/12.png')} />
                    </div>
                    <div >
                        <img alt="events" src={require('../../assets/images/events/13.png')} />
                    </div>
                </Carousel>
            </Col>
        </Row>
    );
}

export default Events;