import { Carousel, Col, Row } from 'antd';
import React from 'react';

const Testimonials = () => {
    return (
        <Row style={{ textAlign: "center", marginTop: 60, paddingBottom: 0 }}>
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center", marginBottom: 25 }}>
                <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#000" }}>Guess what? <span className="banner-centered-text-fontsize" style={{ fontWeight: "bold", color: "#ff6600" }}> Our tenants love us! </span></span>
            </Col>
            <Col lg={24} md={24} sm={24} xs={24} style={{ textAlign: "center", marginBottom: 25 }}>
            </Col>
            <Col lg={24} md={0} sm={0} xs={0}>
                <Carousel arrows autoplay={true}>
                    <div>
                        <Row gutter={8}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/1.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/2.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/3.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/4.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/5.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/6.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/7.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/8.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/9.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/10.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/11.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/12.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/13.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/14.png')} />
                            </Col>
                        </Row>
                    </div>
                    <div>
                        <Row gutter={48}>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/15.png')} />
                            </Col>
                            <Col span={12}>
                                <img alt="testimonial" src={require('../../assets/images/testimonials/8.png')} />
                            </Col>
                        </Row>
                    </div>
                </Carousel>
            </Col>
            <Col lg={0} md={24} sm={24} xs={24}>
                <Carousel arrows autoplay={true}>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/1.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/2.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/3.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/4.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/5.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/6.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/7.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/8.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/9.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/10.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/11.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/12.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/13.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/14.png')} />
                    </div>
                    <div >
                        <img alt="testimonials" src={require('../../assets/images/testimonials/15.png')} />
                    </div>
                </Carousel>
            </Col>
        </Row>
    );
}

export default Testimonials;