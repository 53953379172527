import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "@mui/system";
import "./Navbar.scss";

export default function Navbar(props) {
  const links = [
    {
      name: "Homepage",
      link: "/",
    },
    {
      name: "About Us",
      link: "/about-us",
    },
    {
      name: "Make Enquiry",
      link: "/contact-us",
    },
  ];
  return (
    <nav className="Navbar">
      <Container>
        <ul className="nav__container">
          {links.map((d, i) => {
            return (
              <li key={i}>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "none")}
                  to={d.link}
                >
                  <span>{d.name}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </Container>
    </nav>
  );
}
