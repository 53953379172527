import React from 'react';

const LimitlessSectionImages = ({ src, overlayText }) => {
    return (
        <div class="container">
            <img alt="example" src={src} style={{ width: 300, height: 250, borderRadius: 12 }} />
            <div class="limitless-text-block">
                <p style={{ color: "#000", fontSize: 16, fontWeight: 500, paddingLeft: 15, paddingRight: 15 }}>{overlayText}</p>
            </div>
        </div>
    );
}

export default LimitlessSectionImages;