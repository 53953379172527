import React from "react";
import { Link } from "react-router-dom";
import { Box, Container } from "@mui/system";
import "./Footer.scss";
import { Col, Row, Space } from "antd";
import { FacebookOutlined, LinkedinOutlined, TwitterOutlined } from "@ant-design/icons";

export default function Footer(props) {
  const footerStyle = {};
  return (
    <footer className="component__footer" style={{ padding: 25 }}>
      <Container>
        <div className="component__footer-main">

          <p>Copyright {new Date().getFullYear()} | All Rights Reserved</p>

          <ul>
            <li>
              <Link to="/faqs">FAQs</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-of-use">Terms of use</Link>
              <Link to="/blog">Blogs</Link>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <ul>
            <li style={{ height: 30, paddingTop: 5 }}>
              <a href="https://www.facebook.com/myhostelroom?mibextid=LQQJ4d" target="_blank">
                <span class="facebook-links">
                  <img alt="Facebook" src="https://www.myhostelroom.com/images/facebook.svg" />
                </span></a>
              <a href="https://www.instagram.com/myhostelroom_/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank">
                <span class="insta-links"><img alt="instagtramicon" src="https://www.myhostelroom.com/images/instagram.svg" />
                </span>
              </a>
              <a href="https://www.linkedin.com/company/myhostelroom/" target="_blank">
                <span class="linkedin-links">
                  <img alt="linkedinicon" src="https://www.myhostelroom.com/images/linked-in.svg" />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
}
